"use strict";

var _interopRequireDefault = require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.anchorAuthList = anchorAuthList;
exports.anchorBindBroker = anchorBindBroker;
exports.anchorBrokerInfo = anchorBrokerInfo;
exports.anchorBrokerList = anchorBrokerList;
exports.anchorCardList = anchorCardList;
exports.anchorLastQuitBatch = anchorLastQuitBatch;
exports.anchorList = anchorList;
exports.anchorMissionList = anchorMissionList;
exports.anchorPriceBatch = anchorPriceBatch;
exports.anchorVideoDelete = anchorVideoDelete;
exports.anchorVideosList = anchorVideosList;
exports.cancelAnchor = cancelAnchor;
exports.checkPass = checkPass;
exports.checkRefuse = checkRefuse;
exports.cutOutRecordingVideo = cutOutRecordingVideo;
exports.defaultAvatar = defaultAvatar;
exports.delNaturalAnchor = delNaturalAnchor;
exports.delUserWithAccount = delUserWithAccount;
exports.editAnchorCard = editAnchorCard;
exports.editAnchorLastQuit = editAnchorLastQuit;
exports.editAnchorQualityConfig = editAnchorQualityConfig;
exports.editNaturalAnchor = editNaturalAnchor;
exports.editUserWithAccount = editUserWithAccount;
exports.findEvaluateForPage = findEvaluateForPage;
exports.getAnchorIncomeList = getAnchorIncomeList;
exports.getAnchorInfo = getAnchorInfo;
exports.getAnchorQualityList = getAnchorQualityList;
exports.getGreetingsList = getGreetingsList;
exports.getUserWithAccount = getUserWithAccount;
exports.liveFlag = liveFlag;
exports.naturalAnchorList = naturalAnchorList;
exports.pushFlowFlag = pushFlowFlag;
exports.recommend = recommend;
exports.recordingVideoEdit = recordingVideoEdit;
exports.recordingVideoList = recordingVideoList;
exports.refreshAnchor = refreshAnchor;
exports.reviewAnchorQuality = reviewAnchorQuality;
exports.updateAnchorBackStatus = updateAnchorBackStatus;
exports.updateAnchorBackStatusAll = updateAnchorBackStatusAll;
exports.updateAnchorLevel = updateAnchorLevel;
exports.updateAnchorStatus = updateAnchorStatus;
exports.updateAnchorStatusAll = updateAnchorStatusAll;
var _request = _interopRequireDefault(require("@/utils/request"));
var _axios = _interopRequireDefault(require("axios"));
var _auth = require("@/utils/auth");
/**
 * 获取用户列表
 * @param {*} params
 */
function anchorList(params) {
  return (0, _request.default)({
    url: '/anchor/list',
    method: 'get',
    params: params
  });
}

// 获取优质主播审核列表
function getAnchorQualityList(params) {
  return (0, _request.default)({
    url: '/anchor/anchorQualityList',
    method: 'get',
    params: params
  });
}

// 编辑优质主播审核配置
function editAnchorQualityConfig(data) {
  return (0, _request.default)({
    url: '/anchor/editAnchorQualityConfig',
    method: 'post',
    data: data
  });
}

// 审核通过或取消优质主播身份
function reviewAnchorQuality(data) {
  return (0, _request.default)({
    url: '/anchor/reviewAnchorQuality',
    method: 'post',
    data: data
  });
}

// 获取主播收入
function getAnchorIncomeList(data) {
  return (0, _request.default)({
    url: '/anchor/getAnchorIncomeList',
    method: 'post',
    data: data
  });
}

/**
 * 获取工会信息
 * @param {*} data
 */
function anchorBrokerInfo(data) {
  return (0, _request.default)({
    url: '/anchor/anchorBrokerInfo',
    method: 'POST',
    data: data
  });
}

/**
 * 主播绑定或修改工会
 * @param {*} data
 */
function anchorBindBroker(data) {
  return (0, _request.default)({
    url: '/anchor/anchorBindBroker',
    method: 'POST',
    data: data
  });
}

/**
 * 取消主播身份
 * @param {*} data
 */
function cancelAnchor(data) {
  return (0, _request.default)({
    url: '/anchor/cancel',
    method: 'post',
    data: data
  });
}

/**
 * 刷新主播列表
 * @param {*} data
 */
function refreshAnchor(data) {
  return (0, _request.default)({
    url: '/anchor/refresh',
    method: 'post',
    data: data
  });
}

/**
 * 艺人评价
 * @param {*} params
 */
function findEvaluateForPage(params) {
  return (0, _request.default)({
    url: '/anchor/report/findEvaluateForPage',
    method: 'get',
    params: params
  });
}
/**
 * 获取视频秀列表
 * @param {*} params
 */
function anchorVideosList(params) {
  return (0, _request.default)({
    url: '/anchor/video/list',
    method: 'get',
    params: params
  });
}

/**
 * 删除视频秀
 * @param {*} data
 */
function anchorVideoDelete(data) {
  return (0, _request.default)({
    url: '/anchor/video/delete',
    method: 'post',
    data: data
  });
}

/**
 * 查询审核艺人列表
 * @param {*} params
 */
function anchorAuthList(params) {
  return (0, _request.default)({
    url: '/anchorAuth/list',
    method: 'get',
    params: params
  });
}

/**
 * 获取艺人详情
 * @param {*} data
 */
function getAnchorInfo(data) {
  return (0, _request.default)({
    url: '/anchor/getAnchorInfo',
    method: 'post',
    data: data
  });
}

/**
 * 工会主播复审
 * @param {*} data
 */
function updateAnchorStatus(data) {
  return (0, _request.default)({
    url: '/anchorAuth/edit/status',
    method: 'post',
    data: data
  });
}

/**
 * 工会主播复审-批量
 * @param {*} data
 */
function updateAnchorStatusAll(data) {
  return (0, _request.default)({
    url: '/anchorAuthAll/edit/status',
    method: 'post',
    data: data
  });
}

/**
 * 自然主播审核
 * @param {*} data
 */
function updateAnchorBackStatus(data) {
  return (0, _request.default)({
    url: '/anchorBack/edit/status',
    method: 'post',
    data: data
  });
}

/**
 * 自然主播审核 - 批量
 * @param {*} data
 */
function updateAnchorBackStatusAll(data) {
  return (0, _request.default)({
    url: '/anchorBackAll/edit/status',
    method: 'post',
    data: data
  });
}

/**
 * 获取公会艺人审核列表
 * @param {*} params
 */
function anchorBrokerList(params) {
  return (0, _request.default)({
    url: '/anchorAuth/broker/list',
    method: 'get',
    params: params
  });
}

/**
 * 推荐主播设置
 */
function recommend(data) {
  return (0, _request.default)({
    url: '/anchor/recommend',
    method: 'post',
    data: data
  });
}

/**
 * 更换默认头像
 */
function defaultAvatar(data) {
  return (0, _request.default)({
    url: '/anchor/defaultAvatar',
    method: 'post',
    data: data
  });
}

/**
 * 主播完成任务记录
 * @param {*} params
 */
function anchorMissionList(params) {
  return (0, _request.default)({
    url: '/anchor/mission/list',
    method: 'get',
    params: params
  });
}

/**
 * 直播播权限控制
 */
function liveFlag(data) {
  return (0, _request.default)({
    url: '/anchor/recommend/liveFlag',
    method: 'post',
    data: data
  });
}

/**
 * 获取主播包装列表
 * @param {*} params
 */
function naturalAnchorList(params) {
  return (0, _request.default)({
    url: '/anchor/naturalAnchor/list',
    method: 'get',
    params: params
  });
}

/**
 * 编辑主播包装
 * @param {*} data
 */
function editNaturalAnchor(data) {
  return (0, _request.default)({
    url: '/anchor/naturalAnchor/edit',
    method: 'post',
    data: data
  });
}

/**
 * 删除主播包装
 * @param {*} data
 */
function delNaturalAnchor(data) {
  return (0, _request.default)({
    url: '/anchor/naturalAnchor/del',
    method: 'post',
    data: data
  });
}

// 获取打招呼列表
function getGreetingsList(params) {
  return (0, _request.default)({
    url: '/anchor/getGreetingsList',
    method: 'GET',
    params: params
  });
}
// 审核通过
function checkPass(data) {
  return (0, _request.default)({
    url: '/anchor/checkPass',
    method: 'post',
    data: data
  });
}
// 审核不通过
function checkRefuse(data) {
  return (0, _request.default)({
    url: '/anchor/checkRefuse',
    method: 'post',
    data: data
  });
}
// 批量修改主播置底下榜状态
function anchorLastQuitBatch(data) {
  return (0, _request.default)({
    url: '/anchor/anchorLastQuitBatch',
    method: 'POST',
    data: data
  });
}

// 修改主播置底下榜状态
function editAnchorLastQuit(data) {
  return (0, _request.default)({
    url: '/anchor/editAnchorLastQuit',
    method: 'POST',
    data: data
  });
}

/**
 * 修改主播推流开关
 * @param {*} data
 */
function pushFlowFlag(data) {
  return (0, _request.default)({
    url: '/anchor/pushFlowFlag',
    method: 'post',
    data: data
  });
}

/**
 * 获取主播提现账户列表
 * @param {*} params
 */
function getUserWithAccount(params) {
  return (0, _request.default)({
    url: '/anchor/getUserWithAccount',
    method: 'get',
    params: params
  });
}

/**
 * 编辑/新增主播提现账户
 * @param {*} data
 */
function editUserWithAccount(data) {
  return (0, _request.default)({
    url: '/anchor/editUserWithAccount',
    method: 'post',
    data: data
  });
}

/**
 * 删除主播提现账户
 * @param {*} data
 */
function delUserWithAccount(data) {
  return (0, _request.default)({
    url: '/anchor/delUserWithAccount',
    method: 'post',
    data: data
  });
}

/**
 * 修改主播颜值登记
 * @param {*} data
 */
function updateAnchorLevel(data) {
  return (0, _axios.default)({
    url: "".concat(process.env.VUE_APP_ADMIN_API_JAVA_DOMAIN, "/anchorData/updateAnchorLevel"),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'token': (0, _auth.getToken)()
    },
    data: data
  });
}

/**
 * 获取主播录制视频列表
 * @param {*} data
 */
function recordingVideoList(data) {
  return (0, _axios.default)({
    url: "".concat(process.env.VUE_APP_ADMIN_API_JAVA_DOMAIN, "/anchorData/list/recordVideo"),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'token': (0, _auth.getToken)()
    },
    data: data
  });
  // return request({
  //   url: '/anchor/recordingVideoList',
  //   method: 'get',
  //   params
  // })
}

/**
 * 审核主播录制视频
 * @param {*} data
 */
function recordingVideoEdit(data) {
  return (0, _axios.default)({
    url: "".concat(process.env.VUE_APP_ADMIN_API_JAVA_DOMAIN, "/anchorData/review/recordVideo"),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'token': (0, _auth.getToken)()
    },
    data: data
  });
  // return request({
  //   url: '/anchor/recordingVideoEdit',
  //   method: 'post',
  //   data
  // })
}

/**
 * 截取视频流
 */
function cutOutRecordingVideo(data) {
  return (0, _request.default)({
    url: '/anchor/cutOutRecordingVideo',
    method: 'post',
    data: data
  });
}
// 批量修改主播价格
function anchorPriceBatch(data) {
  return (0, _request.default)({
    url: '/anchor/anchorPriceBatch',
    method: 'POST',
    data: data
  });
}
// 查询主播置顶卡
function anchorCardList(params) {
  return (0, _request.default)({
    url: '/anchor/anchorCardList',
    method: 'GET',
    params: params
  });
}
// 增加主播置顶卡
function editAnchorCard(data) {
  return (0, _request.default)({
    url: '/anchor/editAnchorCard',
    method: 'POST',
    data: data
  });
}