"use strict";

var _interopRequireDefault = require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.accountFilter = accountFilter;
exports.add0 = add0;
exports.anchorTypeFilter = anchorTypeFilter;
exports.brandStatusFilter = brandStatusFilter;
exports.cdnUrlFilter = cdnUrlFilter;
exports.customToFixedFilter = customToFixedFilter;
exports.dateTimeFilter = dateTimeFilter;
Object.defineProperty(exports, "formatTime", {
  enumerable: true,
  get: function get() {
    return _utils.formatTime;
  }
});
exports.goldFilter = goldFilter;
exports.handleStatusFilter = handleStatusFilter;
exports.hangUpTypeFilter = hangUpTypeFilter;
exports.isFilter = isFilter;
exports.moneyFilter = moneyFilter;
exports.numberFormatter = numberFormatter;
Object.defineProperty(exports, "parseTime", {
  enumerable: true,
  get: function get() {
    return _utils.parseTime;
  }
});
exports.paychannelFilter = paychannelFilter;
exports.productTypeFilter = productTypeFilter;
exports.readFilter = readFilter;
exports.timeAgo = timeAgo;
exports.timeToDateFilter = timeToDateFilter;
exports.timeToUTCDateFilter = timeToUTCDateFilter;
exports.toThousandFilter = toThousandFilter;
exports.totalFilter = totalFilter;
exports.transTypeFilter = transTypeFilter;
exports.uppercaseFirst = uppercaseFirst;
exports.userSexFilter = userSexFilter;
exports.userTypeFilter = userTypeFilter;
var _typeof2 = _interopRequireDefault(require("/opt/tentacles/servers/appservers/video-admin-web/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/es6.regexp.match");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.number.constructor");
var _lang = _interopRequireDefault(require("@/lang"));
var _store = _interopRequireDefault(require("@/store"));
var _utils = require("@/utils");
// import parseTime, formatTime and set to filter

/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize(time, label) {
  if (time === 1) {
    return time + label;
  }
  return time + label + 's';
}

/**
 * @param {number} time
 */
function timeAgo(time) {
  var between = Date.now() / 1000 - Number(time);
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute');
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour');
  } else {
    return pluralize(~~(between / 86400), ' day');
  }
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
function numberFormatter(num, digits) {
  var si = [{
    value: 1E18,
    symbol: 'E'
  }, {
    value: 1E15,
    symbol: 'P'
  }, {
    value: 1E12,
    symbol: 'T'
  }, {
    value: 1E9,
    symbol: 'G'
  }, {
    value: 1E6,
    symbol: 'M'
  }, {
    value: 1E3,
    symbol: 'k'
  }];
  for (var i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol;
    }
  }
  return num.toString();
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
function toThousandFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, function (m) {
    return m.replace(/(?=(?!\b)(\d{3})+$)/g, ',');
  });
}

/**
 * Upper case first char
 * @param {String} string
 */
function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * 时间格式化
 * @param {*} time 时间
 * @param {*} formatStr 返回格式
 */
function dateTimeFilter(time, formatStr) {
  if (!time) return '';
  var date = new Date(time);
  var o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
    'q+': Math.floor((date.getMonth() + 3) / 3),
    'S': date.getMilliseconds()
  };
  if (/(y+)/.test(formatStr)) formatStr = formatStr.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(formatStr)) formatStr = formatStr.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
  }
  return formatStr;
}

/**
 * 将时间戳转为时间格式
 * @param {*} time 时间戳
 */
function timeToDateFilter(timestamp) {
  var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  if (!timestamp) return '';
  var time = new Date(timestamp);
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  var h = time.getHours();
  var mm = time.getMinutes();
  var s = time.getSeconds();
  if (type === 0) {
    return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s);
  } else {
    return add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm);
  }
}

/**
 * 将时间戳转为UTC时间格式
 * @param {*} time 时间戳
 */
function timeToUTCDateFilter(timestamp) {
  var formatStr = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'yyyy-MM-dd hh:mm:ss';
  if (!timestamp) return '';
  var date = new Date(timestamp);
  var o = {
    'M+': date.getUTCMonth() + 1,
    'd+': date.getUTCDate(),
    'h+': date.getUTCHours(),
    'm+': date.getUTCMinutes(),
    's+': date.getUTCSeconds(),
    'q+': Math.floor((date.getUTCMonth() + 3) / 3),
    'S': date.getUTCMilliseconds()
  };
  if (/(y+)/.test(formatStr)) formatStr = formatStr.replace(RegExp.$1, (date.getUTCFullYear() + '').substr(4 - RegExp.$1.length));
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(formatStr)) formatStr = formatStr.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
  }
  return formatStr;
}

/**
 * 格式化时间
 * @param {*} m 值
 */
function add0(m) {
  return m < 10 ? '0' + m : m;
}

/**
 * 金额分转元
 * @param {*} num 金额
 * @param {*} bit 保留小数位
 */
function moneyFilter(num) {
  var bit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  if (!num) return 0;
  num = parseInt(num);
  // if (typeof num !== 'number') return 0
  num = parseFloat(num / 100);
  num = num.toFixed(bit);
  return num || 0;
}

/**
 * 语音聊天状态
 * @param {*} val 状态值
 */
function readFilter(val) {
  return val === 0 ? _lang.default.t('filters.unread') : _lang.default.t('filters.haveRead');
}

/**
 * 处理状态
 * @param {*} val 状态值
 */
function handleStatusFilter(val) {
  return val === 1 ? _lang.default.t('filters.unhandle') : _lang.default.t('filters.handle');
}

/**
 * 应用状态
 * @param {*} val 状态值
 */
function brandStatusFilter(val) {
  if (val === 0) {
    val = _lang.default.t('filters.normal');
  } else if (val === 1) {
    val = _lang.default.t('filters.notActivated');
  } else if (val === 2) {
    val = _lang.default.t('filters.offline');
  }
  return val;
}

/**
 * 用户身份
 * @param {*} val
 */
function userTypeFilter(val) {
  if (val === 1) return _lang.default.t('filters.user');
  if (val === 2) return _lang.default.t('filters.anchor');
  if (val === 3) return _lang.default.t('filters.robot');
  if (val === 7) return '审核用户';
  return val;
}

/**
 * 用户性别
 * @param {*} val
 */
function userSexFilter(val) {
  return val === 0 ? _lang.default.t('filters.girl') : _lang.default.t('filters.man');
}

/**
 * 是否
 * @param {*} val
 */
function isFilter(val) {
  return val === 1 ? '是' : '否';
}

/**
 * 礼物类型
 * @param {*} val
 */
function transTypeFilter(val) {
  var typeArr = [{
    type: 1,
    text: '充值金币'
  }, {
    type: 2,
    text: '动态送礼'
  }, {
    type: 3,
    text: '互动送礼'
  }, {
    type: 4,
    text: '提现扣金币'
  }, {
    type: 5,
    text: '视频通话'
  }, {
    type: 6,
    text: '私聊'
  }, {
    type: 7,
    text: '分享奖励'
  }, {
    type: 8,
    text: '视频聊天'
  }, {
    type: 9,
    text: '视频送礼'
  }, {
    type: 10,
    text: '推广奖励'
  }, {
    type: 11,
    text: '语音红包'
  }, {
    type: 12,
    text: '视频红包'
  }, {
    type: 13,
    text: '照片红包'
  }, {
    type: 14,
    text: '任务收入'
  }, {
    type: 15,
    text: '语音通话'
  }, {
    type: 16,
    text: 'vip每日奖励'
  }, {
    type: 17,
    text: '新用户使用免费次数'
  }, {
    type: 18,
    text: '扣除用户挑逗金币'
  }, {
    type: 19,
    text: '主播获得挑逗奖励'
  }, {
    type: 20,
    text: '主播获得挑逗奖励 - 未用'
  }, {
    type: 21,
    text: '新用户充值主播获得奖励'
  }, {
    type: 22,
    text: '绑定手机号奖励'
  }, {
    type: 23,
    text: '购买小视频'
  }, {
    type: 24,
    text: '小视频收入'
  }, {
    type: 25,
    text: '观看视频收入'
  }, {
    type: 26,
    text: '嘉宾上场'
  }, {
    type: 27,
    text: '会场中礼物'
  }, {
    type: 28,
    text: '购买媒体'
  }, {
    type: 29,
    text: '购买媒体'
  }, {
    type: 30,
    text: '签到'
  }, {
    type: 31,
    text: 'VIP用户免费次数使用'
  }, {
    type: 32,
    text: '金币退款'
  }, {
    type: 33,
    text: '会员退款'
  }, {
    type: 34,
    text: '游戏消费'
  }, {
    type: 35,
    text: '游戏奖励'
  }, {
    type: 36,
    text: '合作方扣款单'
  }, {
    type: 0,
    text: '开通会员'
  }, {
    type: 37,
    text: '周明星奖励'
  }, {
    type: 38,
    text: 'match卡'
  }, {
    type: 39,
    text: 'vip特权赠送金币'
  }, {
    type: 40,
    text: '绑定账号送金币'
  }, {
    type: 41,
    text: '幸运转盘'
  }, {
    type: 42,
    text: '金币订阅签到送金币'
  }, {
    type: 43,
    text: '匹配卡订阅签到送匹配卡'
  }, {
    type: 44,
    text: '召回用户赠送金币'
  }, {
    type: 45,
    text: '冻结页解冻付费'
  }, {
    type: 46,
    text: '视频通话任务'
  }, {
    type: 47,
    text: '发送消息任务'
  }, {
    type: 48,
    text: '视频匹配任务'
  }, {
    type: 53,
    text: '商店好评'
  }, {
    type: 54,
    text: '人工充值'
  }, {
    type: 56,
    text: '游戏中的奖励和支出'
  }, {
    type: 57,
    text: '主播邀请收入'
  }, {
    type: 59,
    text: '直播间赠送礼物'
  }, {
    type: 60,
    text: '三方登陆奖励'
  }, {
    type: 61,
    text: '币商充值'
  }, {
    type: 64,
    text: '线下转账'
  }];
  var info = typeArr.find(function (item) {
    return item.type === val;
  });
  return info && info.text || val;
}

/**
 * 挂断类型
 * @param {*} val
 */
function hangUpTypeFilter(hang_up_party, hangup_reason) {
  var hangUpType = [];
  var type = '';
  if (hangup_reason !== null || hangup_reason !== '') {
    type = hangup_reason;
    hangUpType = {
      0: '服务端挂断',
      1: '取消拨打',
      2: '拒接',
      3: '对方忙线',
      4: '超时未接',
      5: '主叫手动挂断',
      6: '被叫手动挂断',
      7: '余额不足挂断',
      8: '被其他应用打断',
      9: 'connect接口调用失败',
      10: '涉黄挂断',
      11: '主播端未收到呼叫',
      12: '主播没有接听',
      13: '推拉流网络质量差',
      15: '链接中取消',
      16: '链接中拒接'
    };
  } else {
    type = hang_up_party;
    hangUpType = {
      1: '用户挂断',
      2: '主播挂断',
      3: '服务端挂断,余额不足',
      4: '网易回调挂断',
      5: '服务端挂断，心跳请求超时'
    };
  }
  return hangUpType[type] || '未知挂断';
}
function totalFilter(nums) {
  var total = 0;
  if (nums && nums.length > 0) {
    nums.forEach(function (item) {
      total += item;
    });
  }
  return total;
}

/**
 * 商品类型filter
 */
function productTypeFilter(val) {
  if (val === 0) {
    val = '金币充值';
  } else if (val === 1) {
    val = 'VIP充值';
  } else if (val === 2) {
    val = '订阅VIP';
  } else if (val === 3) {
    val = '订阅金币';
  } else if (val === 4) {
    val = '订阅匹配卡';
  } else if (val === 5) {
    val = '营销商品';
  } else if (val === 8) {
    val = '匹配卡';
  }
  return val;
}
function paychannelFilter(val) {
  var info = _store.default.getters.channelList.find(function (item) {
    return item.dict_label === val;
  });
  return info && info.remark || val;
}
function customToFixedFilter(number, decimalLength) {
  if (number === '' || number === null || number === 0 || isNaN(number)) {
    return 0;
  }
  var numArr = (number + '').split('.');
  if (numArr[1]) {
    return Number(numArr[0] + '.' + numArr[1].substring(0, decimalLength));
  }
  return number;
}
function accountFilter(val) {
  if (val === 1) {
    return '用户';
  } else if (val === 2) {
    return '主播';
  } else if (val === 7) {
    return '审核用户';
  } else if (val === 9) {
    return '虚拟主播';
  }
}
function goldFilter(val) {
  if (typeof val !== 'number') return val;
  return Number(val / 100);
}

/**
 * cdn地址域名拼接
 */
function cdnUrlFilter(url) {
  var reg = new RegExp(/(\w+):\/\/([^/:]+)(:\d*)?/);
  var resultUrl;
  if (!url || url.length <= 0) {
    resultUrl = url;
  } else if (typeof url === 'string') {
    var result = url.match(reg);
    resultUrl = result ? url : 'https://cdn-video-zego.hala.icu/' + url;
  } else if ((0, _typeof2.default)(url) === 'object') {
    resultUrl = [];
    for (var i = 0; i < url.length; i++) {
      if (typeof url[i] === 'string') {
        resultUrl.push(url[i].match(reg) ? url[i] : 'https://cdn-video-zego.hala.icu/' + url[i]);
      }
    }
  } else {
    resultUrl = url;
  }
  return resultUrl;
}
function anchorTypeFilter(val) {
  var arr = ['老主播', '新主播'];
  return arr[val] || '--';
}